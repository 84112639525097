.s-dashboard-main{

	.content-holder-main{height: 100%; width: 100%; overflow: hidden; display: grid; grid-template-columns: 250px 1fr;}
	.nav-area-holder{
		height: 100vh; border-right: 1px solid #ccc; position: relative;
		
		.main-title-holder{border-bottom: 1px solid #ccc; justify-content: space-between; padding: 1px 0;}
		.main-title{padding: 15px ; font-size: 22px; font-weight: 700; }
		.logout-button{margin-right: 10px; --size: 35px; color: #646464}
		.logout-button::after{display: none;}
		.dropdown-item{color: var(--primary-color); font-weight: 700; justify-content: flex-start;}
		.nav--logout-button-holder{position: absolute; bottom: 10px; left: 10px; width: calc(100% - 20px);}
	}
	.nav-tabs{padding: 10px 20px 0 0;}
	.nav-tabs .nav-link{width: 100%; font-weight: 600; color: var(--primary-color); }
	.main-tabs.nav-tabs{display: grid; gap: 10px;  border-bottom: 0;}
	.main-tabs.nav-tabs .nav-link{border-radius: 0 30px 30px 0; text-align: left; padding-left: 30px;}
	.nav-tabs .nav-link.active{background: var(--primary-color); color: white;}
	.users-cards-list{max-height: calc(100vh - 60px); overflow-y: auto; display: grid; align-items: flex-start; padding: 10px; grid-gap: 10px}
	
	.nav-tabs.inner-tabs{display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; grid-gap: 10px; padding: 14px 10px 0;}
	.nav-tabs.inner-tabs .nav-link{border-radius: 5px 5px 0 0; border: 1px solid #ccc; text-align: center;}
	.nav-tabs.inner-tabs .nav-link.active{border-color: transparent; }
	.chat-list-holder{
		height: 100vh; overflow-y: auto; padding: 20px;
		&.chat-support{height: calc(100vh - 120px);}

		.chat-list{
			display: flex; flex-direction: column; gap: 10px; position: relative; z-index: 9; justify-items: flex-start; overflow-y: auto; padding: 10px;
			
			.item{max-width: 80%; align-self: flex-end; background: #ffffff; color: #1d1d1d; border-radius: 15px 15px 0 15px; padding: 10px 15px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);}
			.item.left{max-width: 80%; align-self: flex-start; background: #ff4b4b; color: white; border-radius: 15px 15px 15px 0; padding: 10px 15px;}
		}
	}
	.write-msg-holder{
		border-top: 1px solid #ccc; display: flex; gap: 10px; align-items: center; padding: 5px 10px;
	
		.input-holder{width: 100%;}
		.form-control{height: 49px; width: 100%;}
		.send-message-button{--size: 40px; background: var(--primary-color); color: white; align-items: center; padding-bottom: 4px;}  
	}

	.message-date{font-size: 14px;}

	.back-tab-screen-button{--size: 30px; position: absolute; padding: 10px 20px; top: 2px; cursor: pointer;}
	.back-tab-screen-button:hover{color: var(--primary-color);}
}